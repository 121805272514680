/* This file is meant to be mixed into formItems; see ./README. */

.form-item {
  margin-top: 1.125rem; /* 18px */
  @screen tablet {
    margin-top: 1.5rem; /* 24px */
  }

  &:first-child:not(.noninitial) {
    @apply mt-0;
    @screen tablet {
      @apply mt-0;
    }
  }

  /*v Ad hoc. */
  &.group-with-item-above {
    margin-top: 0.375rem; /* 6px */
    @screen tablet {
      margin-top: 0.5rem; /* 8px */
    }
  }

  .row-2 {
    .column-2 {
      /*v Relative-positioned so it's the containing block for FormErrorTip (except in FormCheckbox). */
      @apply relative;
    }
  }

  &.labels-at-left {
    .row-1 {
      /*v Relative-positioned so it's the containing block for notes and modifiers (except in FormCheckbox [deleted]). */
      @apply relative;
    }

    .row-2 {
      /*v Justification is irrelevant, because the widths of the columns sum to 100%. The only reason for making the row a flexbox is to make the columns */
      /*v vertically centered within it.                                                                                                                  */
      @apply flex items-center;

      .column-1, .column-3 {
	@apply w-1/4;
      }

      .column-2 {
	@apply w-1/2;
      }
    }
  }

  &.labels-on-top {
    .row-1 {
      @apply flex justify-between items-baseline;
    }
  }

  .note-container, .modifiers-container {
    @apply text-content--text-2;
    @apply text-sm-phone;
    @screen tablet {
      @apply text-sm;
    }
    @apply leading-none;
  }

  .note-container {
    @apply italic;
  }

  &.labels-at-left {
    .row-1 {
      /*^ Avoid affecting FormCheckbox, whose note is in row 2. */
      .note-container, .modifiers-container {
	@apply absolute;
	top: -0.703125rem; /* -11.25px */
	@screen tablet {
	  top: -0.9375rem; /* -15px */
	}
      }

      .note-container {
	@apply left-1/4;
      }

      .modifiers-container {
	@apply right-1/4;
      }
    }
  }

  .auxiliary {
    .auxiliary-content {
      button {
	@apply flex justify-center items-center;
	height: 1.3125rem; /* 21px */
	@screen tablet {
	  height: 1.75rem; /* 28px */
	}
	@apply
	  w-full
	  border
	  border-button-secondary--border-and-text
	  if-hover-is-supported:hover:border-button-secondary--border-and-text-hover
	  rounded-full
	  bg-button-secondary--background
	  text-button-secondary--border-and-text
	  if-hover-is-supported:hover:text-button-secondary--border-and-text-hover;

	.icon {
	  height: 0.875rem; /* 14px */
	  width: 0.875rem; /* 14px */
	  @screen tablet {
	    height: 1.125rem; /* 18px */
	    width: 1.125rem; /* 18px */
	  }
	  margin-right: 0.125rem; /* 2px */
	  @apply fill-current;
	}
      }

      /*v The auxiliary may be disabled even when the formItem (if it's a formInput and hence disableable) isn't. */
      &.disabled {
	button {
	  @apply
	    pointer-events-none
	    border-button-secondary--border-and-text-disabled
	    bg-button-secondary--background-disabled
	    text-button-secondary--border-and-text-disabled;
	}
      }
    }
  }

  &.labels-at-left {
    .auxiliary {
      margin-left: 25%;
      @apply w-3/4;
    }
  }

  &.labels-on-top {
    .auxiliary {
      margin-top: 0.75rem; /* 12px */
      @screen tablet {
	margin-top: 1rem; /* 16px */
      }
      @apply mx-auto w-1/2;
    }
  }
}
