html {
  /*v The value here is just a guess. See ../packs/application.js under "Scrollbar width". */
  --scrollbar-width: 15px;
  /*v See                                                           */
  /*v                                                               */
  /*v https://css-tricks.com/the-trick-to-viewport-units-on-mobile/ */
  /*v                                                               */
  /*v and ../packs/application.js under "Viewport height".          */
  --vh: 1vh;
  /*v See ../vues/shared/viewport/viewport.js. */
  --viewport-width-is-above-tablet-threshold: 0;
  @screen tablet {
    --viewport-width-is-above-tablet-threshold: 1;
  }

  /*v Suppress a defect of mobile Chrome. See                                                                */
  /*v                                                                                                        */
  /*v https://stackoverflow.com/a/52702020                                                                   */
  /*v                                                                                                        */
  /*v (The Stack Overflow page doesn't say, but mobile Firefox doesn't seem to have this particular defect.) */
  @apply overflow-hidden;
  -webkit-tap-highlight-color: transparent;

  /*v Suppress another defect of mobile Chrome; see                                                                                                          */
  /*v                                                                                                                                                        */
  /*v https://stackoverflow.com/questions/13430897/how-to-override-font-boosting-in-mobile-chrome .                                                          */
  /*v                                                                                                                                                        */
  /*v (It was obnoxious of Google to introduce this misfeature. It's worse that ten years later, it's still around. And it isn't even implemented correctly! */
  /*v I (Ralph Haygood) have seen text get "boosted" on click or page load but un-"boosted" on device rotation followed by un-rotation, which makes no sense */
  /*v at all.)                                                                                                                                               */
  * {
    max-height: 999999px;
  }
}
