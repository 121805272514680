
































.flicker {
  @apply fixed top-1/2 left-1/2;
  transform: translate(-50%, -50%);
  /*v In document stacking context. Above all (except subsequent dialogs or flickers). */
  @apply z-50;
  @apply box-shadows;
  border-radius: 0.1875rem; /* 3px */
  @apply bg-flicker--background;
  padding: 0.5625rem 0.53125rem; /* 9px 8.5px */
  @screen tablet {
    padding: 0.75rem 0.6875rem; /* 12px 11px */
  }
  @apply text-flicker--text;
  @apply text-base-phone;
  @screen tablet {
    @apply text-base;
  }
  @apply leading-none font-serif;
}
