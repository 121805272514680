/* This file is meant to be mixed into formFields; see ./README. */

@import "./formVisorable";

.form-field {
  input, textarea {
    @apply
      block
      w-full
      border
      border-form-input--border
      bg-form-input--background
      if-hover-is-supported:hover:bg-form-input--background-hover
      text-form-input--text
      placeholder-form-input--text-placeholder;
    @apply text-base-phone;
    @screen tablet {
      @apply text-base;
    }

    /*v Generate another rule with the same selector, so that the focus style overrides the hover style. */
    & {
      @apply focus:bg-form-input--background-active;
    }
  }

  input {
    height: 2.5rem; /* 40px */
    border-radius: 0.3125rem; /* 5px */
    padding: 0 0.375rem; /* 0 6px */
    @screen tablet {
      padding: 0 0.5rem; /* 0 8px */
    }
    @apply leading-none;

    /*v Per                                    */
    /*v                                        */
    /*v https://stackoverflow.com/a/60987373 . */
    &:-webkit-autofill::first-line {
      @apply text-base-phone;
      @screen tablet {
	@apply text-base;
      }
      font-family: inherit;
    }
  }

  textarea {
    @apply resize-none;
    border-top-left-radius: 0.3125rem; /* 5px */
    border-top-right-radius: calc(var(--scrollbar-width-is-zero)*0.3125rem); /* 5px or 0 */
    border-bottom-right-radius: calc(var(--scrollbar-width-is-zero)*0.3125rem); /* 5px or 0 */
    border-bottom-left-radius: 0.3125rem; /* 5px */
    @apply overflow-y-scroll overscroll-none;
    padding: 0.1875rem 0.375rem; /* 3px 6px */
    @apply leading-base-phone;
    @screen tablet {
      padding: 0.25rem 0.5rem; /* 4px 8px */
      @apply leading-base;
    }
  }

  &.with-visor {
    input, textarea {
      @apply rounded-b-none;
    }
  }

  &.disabled {
    @apply pointer-events-none;

    input, textarea {
      @apply opacity-50 bg-form-input--background-disabled text-form-input--text-disabled;
    }
  }
}
