/* See app/views/readers/read.html.erb and app/views/subscribers/newsletter.html.erb. */

.markdown {
  h1 {
    @apply text-4xl-phone leading-4xl-phone;
    @screen tablet {
      @apply text-4xl leading-4xl;
    }
  }

  blockquote, ol, ul, li {
    margin-top: 0.9375rem; /* 15px */
    @screen tablet {
      margin-top: 1.25rem; /* 20px */
    }

    &:first-child {
      @apply mt-0;
    }
  }

  blockquote {
    padding: 0 1.875rem; /* 0 30px */
    @screen tablet {
      padding: 0 2.5rem; /* 0 40px */
    }
  }

  ol, ul {
    padding: 0 0.9375rem; /* 0 15px */
    @screen tablet {
      padding: 0 1.25rem; /* 0 20px */
    }
    @apply font-serif;
  }

  ol {
    @apply list-decimal;
  }

  ul {
    @apply list-disc;
  }

  .footnotes {
    margin-top: 1.3125rem; /* 21px */
    @screen tablet {
      margin-top: 1.75rem; /* 28px */
    }
    @apply border-t border-content--border-internal;

    ol {
      margin-top: 1.25rem; /* 20px */
      @screen tablet {
	margin-top: 1.6875rem; /* 27px */
      }

      li, p {
	margin-top: 0.75rem; /* 12px */
	@screen tablet {
	  margin-top: 1rem; /* 16px */
	}
	@apply text-sm-phone leading-sm-phone;
	@screen tablet {
	  @apply text-sm leading-sm;
	}

	&:first-child {
	  @apply mt-0;
	}
      }
    }
  }
}
