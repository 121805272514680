/* Tailwind */
@tailwind base;
@tailwind components;
@tailwind utilities;
/* "Free" isn't free */
body {
  /*v Else absolute-positioned children (menus, tips, etc.) are misplaced. */
  @apply relative;
  @apply bg-content--background-1 text-content--text-1 font-sans; }
  body .in-text {
    @apply font-serif; }
  body h1, body h2, body h3, body h4 {
    @apply font-bold; }
  body h1 {
    @apply text-2xl-phone leading-2xl-phone; }

@screen tablet {
  body h1 {
    @apply text-2xl leading-2xl; } }
    body h1.extra-large {
      @apply text-4xl-phone leading-4xl-phone; }

@screen tablet {
  body h1.extra-large {
    @apply text-4xl leading-4xl; } }
  body h2, body h3, body h4, body p, body form {
    margin-top: 0.9375rem;
    /* 15px */ }

@screen tablet {
  body h2, body h3, body h4, body p, body form {
    margin-top: 1.25rem;
    /* 20px */ } }
    body h2:first-child:not(.noninitial), body h3:first-child:not(.noninitial), body h4:first-child:not(.noninitial), body p:first-child:not(.noninitial), body form:first-child:not(.noninitial) {
      @apply mt-0; }
  body h2 {
    @apply text-xl-phone leading-xl-phone; }

@screen tablet {
  body h2 {
    @apply text-xl leading-xl; } }
  body h1.extra-large + h2 {
    margin-top: 0.75rem;
    /* 12px */ }

@screen tablet {
  body h1.extra-large + h2 {
    margin-top: 1rem;
    /* 16px */ } }
  body h3 {
    @apply text-lg-phone leading-lg-phone; }

@screen tablet {
  body h3 {
    @apply text-lg leading-lg; } }
  body h4 {
    @apply text-base-phone leading-base-phone; }

@screen tablet {
  body h4 {
    @apply text-base leading-base; } }
  body p {
    @apply text-base-phone leading-base-phone;
    @apply font-serif; }

@screen tablet {
  body p {
    @apply text-base leading-base; } }
  body p.note {
    @apply text-content--text-2;
    @apply text-sm-phone leading-sm-phone; }

@screen tablet {
  body p.note {
    @apply text-sm leading-sm; } }
  body a, body button, body input[type="submit"] {
    @apply text-content-control--text if-hover-is-supported:hover:text-content-control--text-hover; }
  body a.active, body button.active {
    @apply text-content-control--text-hover; }
  body button, body input[type="submit"] {
    font-weight: inherit; }
  body button, body input, body textarea {
    @apply focus:outline-none; }
  body input[type="submit"] {
    @apply cursor-pointer bg-transparent; }
  body ::selection {
    @apply bg-content--background-selected; }

/*v The following rule isn't subordinate to the body selector, because the Turbolinks progress bar isn't a descendant of body, believe it or not. */
.turbolinks-progress-bar {
  @apply bg-progress-bar--background; }

/* See app/views/layouts/application.html.erb. */
#content-wrapper {
  /*v Make this element what might be called the scroll parent of last resort for absolute-positioned descendants such as help tips. */
  @apply relative;
  /*v Should complement height of #header, minus 2px to avoid interference with box shadow on #header; see ./header.scss and ./tailwind.config.js. */
  height: calc(100*var(--vh) - 3rem - 2px);
  @apply overflow-y-scroll overscroll-none;
  scroll-behavior: smooth; }

@screen tablet {
  #content-wrapper {
    height: calc(100*var(--vh) - 4rem - 2px); } }
  #content-wrapper #content {
    /*v Duration should match fade-fast; see ./transitions.scss. */
    transition: opacity 0.1s;
    @apply opacity-0 mx-auto;
    /*v Should match #header; see ./header.scss. Influences calculation of width of .dialog .box.not-fullscreen.wide and max-width of .tip; see */
    /*v ../vues/shared/dialogs/DialogComponent.vue and ../vues/shared/tips/TipComponent.vue.                                                    */
    max-width: 46rem;
    /* 736px */
    /*v Horizontal padding should match #header; see ./header.scss. */
    padding: 1.125rem 1.3125rem;
    /* 18px 21px */ }

@screen tablet {
  #content-wrapper #content {
    /*v Right padding should match right margin and width of .image; see ./home.scss. */
    padding: 1.5rem 1.75rem;
    /* 24px 28px */ } }
    #content-wrapper #content.is-visible {
      @apply opacity-100; }

@font-face {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/pts55f-webfont.woff2") format("woff2"), url("/fonts/pts55f-webfont.woff") format("woff"); }

@font-face {
  font-family: "PT Sans";
  font-style: italic;
  font-weight: 400;
  src: url("/fonts/pts56f-webfont.woff2") format("woff2"), url("/fonts/pts56f-webfont.woff") format("woff"); }

@font-face {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/pts75f-webfont.woff2") format("woff2"), url("/fonts/pts75f-webfont.woff") format("woff"); }

@font-face {
  font-family: "PT Sans";
  font-style: italic;
  font-weight: 700;
  src: url("/fonts/pts76f-webfont.woff2") format("woff2"), url("/fonts/pts76f-webfont.woff") format("woff"); }

@font-face {
  font-family: "PT Sans Narrow";
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/ptn57f-webfont.woff2") format("woff2"), url("/fonts/ptn57f-webfont.woff") format("woff"); }

@font-face {
  font-family: "PT Sans Narrow";
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/ptn77f-webfont.woff2") format("woff2"), url("/fonts/ptn77f-webfont.woff") format("woff"); }

@font-face {
  font-family: "PT Serif";
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/ptf55f-webfont.woff2") format("woff2"), url("/fonts/ptf55f-webfont.woff") format("woff"); }

@font-face {
  font-family: "PT Serif";
  font-style: italic;
  font-weight: 400;
  src: url("/fonts/ptf56f-webfont.woff2") format("woff2"), url("/fonts/ptf56f-webfont.woff") format("woff"); }

@font-face {
  font-family: "PT Serif";
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/ptf75f-webfont.woff2") format("woff2"), url("/fonts/ptf75f-webfont.woff") format("woff"); }

@font-face {
  font-family: "PT Serif";
  font-style: italic;
  font-weight: 700;
  src: url("/fonts/ptf76f-webfont.woff2") format("woff2"), url("/fonts/ptf76f-webfont.woff") format("woff"); }

/*v "But icon fonts are BAAAD!", I (Ralph Haygood) hear you cry (if you're a conventionally brought-up web programmer); see, for example,                    */
/*v                                                                                                                                                          */
/*v https://www.irigoyen.dev/blog/2021/02/17/stop-using-icon-fonts/                                                                                          */
/*v                                                                                                                                                          */
/*v (if you haven't heard it all already). As with most moralizing about programming, reality isn't so simple. That blog post accuses icon fonts of being    */
/*v "difficult to style/position". However, that depends on what you wish to style or position icons with respect to. If, say, you want an icon to appear    */
/*v immediately before or after a string and, perhaps, share the size or color of the string, there's no easier way than to include the icon in the string.  */
/*v                                                                                                                                                          */
/*v For example, the FormLabel component (see app/client/vues/shared/forms/FormLabel.vue) needs to place a help nubbin immediately after a label string. The */
/*v string and nubbin together must occupy no more than a certain maximum width (namely 25% of the width of the parent form component). Accordingly, if the  */
/*v string is too long, it should be broken into two lines (or, conceivably, more). If the nubbin were a free-standing SVG, getting it to follow the string, */
/*v both when the string is broken and when it isn't, and never on a line by itself, would be a hassle - not impossible, but a hassle. As things are, with   */
/*v the nubbin available in a font, it's trivial.                                                                                                            */
@font-face {
  font-family: "help-nubbin";
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/help_nubbin.woff") format("woff");
  font-display: block; }

[class^="help-nubbin-"], [class*=" help-nubbin-"] {
  font-style: normal;
  font-weight: 400;
  font-family: "help-nubbin" !important;
  speak: never;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased; }

.help-nubbin-help:before {
  content: "\e900"; }

/* See app/views/layouts/_header.html.erb. */
#header-wrapper {
  /*v Make z-indexable. */
  @apply relative;
  /*v In document stacking context. Above #manifest. */
  @apply z-20;
  /*v Avoid interference with box shadow; see ./tailwind.config.js. */
  margin-bottom: 2px;
  @apply box-shadows bg-chrome--background-1;
  padding-right: var(--scrollbar-width); }
  #header-wrapper #header {
    @apply flex justify-between items-center mx-auto;
    /*v Should complement height of #content-wrapper and #manifest; see ./content.scss and ./manifest.scss. */
    height: 3rem;
    /* 48px */
    /*v Should match #content; see ./content.scss. */
    max-width: 46rem;
    /* 736px */
    /*v Horizontal padding should match #content; see ./content.scss. */
    padding: 0 1.3125rem;
    /* 0 21px */
    @apply font-bold;
    @apply text-base-phone;
    @apply leading-none font-sans; }

@screen tablet {
  #header-wrapper #header {
    height: 4rem;
    /* 64px */ } }

@screen tablet {
  #header-wrapper #header {
    padding: 0 1.75rem;
    /* 0 28px */ } }

@screen tablet {
  #header-wrapper #header {
    @apply text-base; } }
    #header-wrapper #header .logo {
      @apply text-2xl-phone;
      @apply leading-none; }

@screen tablet {
  #header-wrapper #header .logo {
    @apply text-2xl; } }
      #header-wrapper #header .logo a {
        @apply text-chrome-logo--text if-hover-is-supported:hover:text-chrome-logo--text-hover; }
    #header-wrapper #header a, #header-wrapper #header button, #header-wrapper #header input[type="submit"] {
      @apply text-chrome--text if-hover-is-supported:hover:text-chrome-control--text-hover fill-current; }
      #header-wrapper #header a.disabled, #header-wrapper #header button.disabled, #header-wrapper #header input[type="submit"].disabled {
        @apply pointer-events-none text-chrome-control--text-disabled; }
    #header-wrapper #header #log-out-button {
      /*v The button_to helper attaches the log-out-button class to an input[type="submit"] element. The leading-none class applied to the #header element */
      /*v causes the descender of the "g" in "Log" to be cut off, for reasons unclear.                                                                     */
      @apply leading-base-phone; }

@screen tablet {
  #header-wrapper #header #log-out-button {
    @apply leading-base; } }
    #header-wrapper #header .with-icon {
      @apply flex justify-start items-center; }
      #header-wrapper #header .with-icon .icon {
        height: theme("fontSize.xl-phone");
        width: theme("fontSize.xl-phone"); }

@screen tablet {
  #header-wrapper #header .with-icon .icon {
    height: theme("fontSize.xl");
    width: theme("fontSize.xl"); } }
      #header-wrapper #header .with-icon .label {
        @apply hidden; }

@screen tablet {
  #header-wrapper #header .with-icon .label {
    @apply inline; } }

/* See app/views/subscribers/home.html.erb. */
@screen tablet {
  .blurb-section .blurb {
    @apply w-1/2; } }

@screen tablet {
  .blurb-section .blurb h1, .blurb-section .blurb h2, .blurb-section .blurb p {
    margin-right: 2.5rem;
    /* 40px */ } }

.blurb-section .image {
  @apply hidden; }

@screen tablet {
  .blurb-section .image {
    @apply block float-right;
    /*v Should match width below and right padding of #content; see ./content.scss. */
    margin-right: -1.75rem;
    /* -28px */
    margin-top: 0.5rem;
    /* 8px */
    /*v Should match right margin above. */
    width: calc(50% + 1.75rem); } }

.buy-section {
  @apply clear-right; }

.archive-section table {
  @apply table-fixed border-separate w-full;
  border-spacing: 0 0.9375rem;
  /* 0 15px */ }

@screen tablet {
  .archive-section table {
    border-spacing: 0 1.25rem;
    /* 0 20px */ } }
  .archive-section table td {
    @apply align-baseline; }
  .archive-section table td:first-child {
    @apply w-3/4;
    padding-right: 1.875rem;
    /* 30px */ }

@screen tablet {
  .archive-section table td:first-child {
    padding-right: 2.5rem;
    /* 40px */ } }
  .archive-section table td:last-child {
    @apply w-1/4; }

.section {
  margin-top: 1.875rem;
  /* 30px */ }

@screen tablet {
  .section {
    margin-top: 2.5rem;
    /* 40px */ } }

.section:first-child {
  @apply mt-0; }

html {
  /*v The value here is just a guess. See ../packs/application.js under "Scrollbar width". */
  --scrollbar-width: 15px;
  /*v See                                                           */
  /*v                                                               */
  /*v https://css-tricks.com/the-trick-to-viewport-units-on-mobile/ */
  /*v                                                               */
  /*v and ../packs/application.js under "Viewport height".          */
  --vh: 1vh;
  /*v See ../vues/shared/viewport/viewport.js. */
  --viewport-width-is-above-tablet-threshold: 0;
  /*v Suppress a defect of mobile Chrome. See                                                                */
  /*v                                                                                                        */
  /*v https://stackoverflow.com/a/52702020                                                                   */
  /*v                                                                                                        */
  /*v (The Stack Overflow page doesn't say, but mobile Firefox doesn't seem to have this particular defect.) */
  @apply overflow-hidden;
  -webkit-tap-highlight-color: transparent;
  /*v Suppress another defect of mobile Chrome; see                                                                                                          */
  /*v                                                                                                                                                        */
  /*v https://stackoverflow.com/questions/13430897/how-to-override-font-boosting-in-mobile-chrome .                                                          */
  /*v                                                                                                                                                        */
  /*v (It was obnoxious of Google to introduce this misfeature. It's worse that ten years later, it's still around. And it isn't even implemented correctly! */
  /*v I (Ralph Haygood) have seen text get "boosted" on click or page load but un-"boosted" on device rotation followed by un-rotation, which makes no sense */
  /*v at all.)                                                                                                                                               */ }

@screen tablet {
  html {
    --viewport-width-is-above-tablet-threshold: 1; } }
  html * {
    max-height: 999999px; }

/* See app/views/layouts/_manifest.html.erb. */
#manifest {
  transition: transform 0.1s;
  @apply absolute;
  /*v Should match height of #header, plus 2px to avoid interference with box shadow on #header; see ./header.scss and ./tailwind.config.js. */
  top: calc(3rem + 2px);
  /* 48px + 2px */
  transform: translate(-100%, 0);
  /*v In document stacking context. Below #header-wrapper. */
  @apply z-10;
  @apply box-shadows;
  /*v Should complement height of #header, minus 2px to avoid interference with box shadow on #header; see ./header.scss and ./tailwind.config.js. */
  height: calc(100*var(--vh) - 3rem - 2px);
  max-width: theme("screens.phone");
  @apply overflow-y-scroll overscroll-none;
  scroll-behavior: smooth;
  @apply bg-chrome--background-2;
  padding: 0.9375rem 0 0.9375rem 0.9375rem;
  /* 15px 0 15px 15px */
  @apply text-base-phone leading-base-phone;
  @apply font-sans; }

@screen tablet {
  #manifest {
    top: calc(4rem + 2px);
    /* 64px + 2px */ } }

@screen tablet {
  #manifest {
    height: calc(100*var(--vh) - 4rem - 2px); } }

@screen tablet {
  #manifest {
    padding: 1.25rem 0 1.25rem 1.25rem;
    /* 20px 0 20px 20px */
    @apply text-base leading-base; } }
  #manifest.is-open {
    transform: translate(0, 0); }
  #manifest li {
    margin-top: 0.5625rem;
    /* 9px */
    @apply border-r-3;
    @apply border-chrome--background-2;
    padding-right: 0.75rem;
    /* 12px */ }

@screen tablet {
  #manifest li {
    margin-top: 0.75rem;
    /* 12px */
    @apply border-r-4; } }

@screen tablet {
  #manifest li {
    padding-right: 1rem;
    /* 16px */ } }
    #manifest li:first-child {
      @apply mt-0; }
    #manifest li.level-1 {
      padding-left: 0.9375rem;
      /* 15px */ }

@screen tablet {
  #manifest li.level-1 {
    padding-left: 1.25rem;
    /* 20px */ } }
    #manifest li.active {
      @apply border-content-control--text-hover; }
      #manifest li.active a {
        @apply text-content-control--text-hover; }

/* See app/views/readers/read.html.erb and app/views/subscribers/newsletter.html.erb. */
.markdown h1 {
  @apply text-4xl-phone leading-4xl-phone; }

@screen tablet {
  .markdown h1 {
    @apply text-4xl leading-4xl; } }

.markdown blockquote, .markdown ol, .markdown ul, .markdown li {
  margin-top: 0.9375rem;
  /* 15px */ }

@screen tablet {
  .markdown blockquote, .markdown ol, .markdown ul, .markdown li {
    margin-top: 1.25rem;
    /* 20px */ } }
  .markdown blockquote:first-child, .markdown ol:first-child, .markdown ul:first-child, .markdown li:first-child {
    @apply mt-0; }

.markdown blockquote {
  padding: 0 1.875rem;
  /* 0 30px */ }

@screen tablet {
  .markdown blockquote {
    padding: 0 2.5rem;
    /* 0 40px */ } }

.markdown ol, .markdown ul {
  padding: 0 0.9375rem;
  /* 0 15px */
  @apply font-serif; }

@screen tablet {
  .markdown ol, .markdown ul {
    padding: 0 1.25rem;
    /* 0 20px */ } }

.markdown ol {
  @apply list-decimal; }

.markdown ul {
  @apply list-disc; }

.markdown .footnotes {
  margin-top: 1.3125rem;
  /* 21px */
  @apply border-t border-content--border-internal; }

@screen tablet {
  .markdown .footnotes {
    margin-top: 1.75rem;
    /* 28px */ } }
  .markdown .footnotes ol {
    margin-top: 1.25rem;
    /* 20px */ }

@screen tablet {
  .markdown .footnotes ol {
    margin-top: 1.6875rem;
    /* 27px */ } }
    .markdown .footnotes ol li, .markdown .footnotes ol p {
      margin-top: 0.75rem;
      /* 12px */
      @apply text-sm-phone leading-sm-phone; }

@screen tablet {
  .markdown .footnotes ol li, .markdown .footnotes ol p {
    margin-top: 1rem;
    /* 16px */ } }

@screen tablet {
  .markdown .footnotes ol li, .markdown .footnotes ol p {
    @apply text-sm leading-sm; } }
      .markdown .footnotes ol li:first-child, .markdown .footnotes ol p:first-child {
        @apply mt-0; }

/* fade-fast */
.fade-fast-appear, .fade-fast-enter, .fade-fast-leave-to {
  @apply opacity-0; }

.fade-fast-appear-active, .fade-fast-enter-active, .fade-fast-leave-active {
  transition: all 0.1s; }

/* fade-fast-unless-phone */
.fade-fast-unless-phone-appear, .fade-fast-unless-phone-enter, .fade-fast-unless-phone-leave-to {
  @apply opacity-0; }

.fade-fast-unless-phone-appear-active, .fade-fast-unless-phone-enter-active, .fade-fast-unless-phone-leave-active {
  transition: all 0; }

@screen tablet {
  .fade-fast-unless-phone-appear-active, .fade-fast-unless-phone-enter-active, .fade-fast-unless-phone-leave-active {
    transition: all 0.1s; } }
