body {
  /*v Else absolute-positioned children (menus, tips, etc.) are misplaced. */
  @apply relative;
  @apply bg-content--background-1 text-content--text-1 font-sans;

  .in-text {
    @apply font-serif;
  }

  h1, h2, h3, h4 {
    @apply font-bold;
  }

  h1 {
    @apply text-2xl-phone leading-2xl-phone;
    @screen tablet {
      @apply text-2xl leading-2xl;
    }

    &.extra-large {
      @apply text-4xl-phone leading-4xl-phone;
      @screen tablet {
	@apply text-4xl leading-4xl;
      }
    }
  }

  h2, h3, h4, p, form {
    margin-top: 0.9375rem; /* 15px */
    @screen tablet {
      margin-top: 1.25rem; /* 20px */
    }

    &:first-child:not(.noninitial) {
      @apply mt-0;
    }
  }

  h2 {
    @apply text-xl-phone leading-xl-phone;
    @screen tablet {
      @apply text-xl leading-xl;
    }
  }

  h1.extra-large + h2 {
    margin-top: 0.75rem; /* 12px */
    @screen tablet {
      margin-top: 1rem; /* 16px */
    }
  }

  h3 {
    @apply text-lg-phone leading-lg-phone;
    @screen tablet {
      @apply text-lg leading-lg;
    }
  }

  h4 {
    @apply text-base-phone leading-base-phone;
    @screen tablet {
      @apply text-base leading-base;
    }
  }

  p {
    @apply text-base-phone leading-base-phone;
    @screen tablet {
      @apply text-base leading-base;
    }
    @apply font-serif;
  }

  p.note {
    @apply text-content--text-2;
    @apply text-sm-phone leading-sm-phone;
    @screen tablet {
      @apply text-sm leading-sm;
    }
  }

  a, button, input[type="submit"] {
    @apply text-content-control--text if-hover-is-supported:hover:text-content-control--text-hover;
  }

  a.active, button.active {
    @apply text-content-control--text-hover;
  }

  button, input[type="submit"] {
    font-weight: inherit;
  }

  button, input, textarea {
    @apply focus:outline-none;
  }

  input[type="submit"] {
    @apply cursor-pointer bg-transparent;
  }

  ::selection {
    @apply bg-content--background-selected;
  }
}

/*v The following rule isn't subordinate to the body selector, because the Turbolinks progress bar isn't a descendant of body, believe it or not. */
.turbolinks-progress-bar {
  @apply bg-progress-bar--background;
}
