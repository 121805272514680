/* See app/views/subscribers/home.html.erb. */

.blurb-section {
  .blurb {
    @screen tablet {
      @apply w-1/2;
    }

    h1, h2, p {
      @screen tablet {
	margin-right: 2.5rem; /* 40px */
      }
    }
  }

  .image {
    @apply hidden;
    @screen tablet {
      @apply block float-right;
      /*v Should match width below and right padding of #content; see ./content.scss. */
      margin-right: -1.75rem; /* -28px */
      margin-top: 0.5rem; /* 8px */
      /*v Should match right margin above. */
      width: calc(50% + 1.75rem);
    }
  }
}

.buy-section {
  @apply clear-right;
}

.archive-section {
  table {
    @apply table-fixed border-separate w-full;
    border-spacing: 0 0.9375rem; /* 0 15px */
    @screen tablet {
      border-spacing: 0 1.25rem; /* 0 20px */
    }

    td {
      @apply align-baseline;
    }

    td:first-child {
      @apply w-3/4;
      padding-right: 1.875rem; /* 30px */
      @screen tablet {
	padding-right: 2.5rem; /* 40px */
      }
    }

    td:last-child {
      @apply w-1/4;
    }
  }
}

.section {
  margin-top: 1.875rem; /* 30px */
  @screen tablet {
    margin-top: 2.5rem; /* 40px */
  }
}

.section:first-child {
  @apply mt-0;
}
