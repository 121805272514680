@font-face {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/pts55f-webfont.woff2") format("woff2"), url("/fonts/pts55f-webfont.woff") format("woff");
}

@font-face {
  font-family: "PT Sans";
  font-style: italic;
  font-weight: 400;
  src: url("/fonts/pts56f-webfont.woff2") format("woff2"), url("/fonts/pts56f-webfont.woff") format("woff");
}

@font-face {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/pts75f-webfont.woff2") format("woff2"), url("/fonts/pts75f-webfont.woff") format("woff");
}

@font-face {
  font-family: "PT Sans";
  font-style: italic;
  font-weight: 700;
  src: url("/fonts/pts76f-webfont.woff2") format("woff2"), url("/fonts/pts76f-webfont.woff") format("woff");
}

@font-face {
  font-family: "PT Sans Narrow";
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/ptn57f-webfont.woff2") format("woff2"), url("/fonts/ptn57f-webfont.woff") format("woff");
}

@font-face {
  font-family: "PT Sans Narrow";
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/ptn77f-webfont.woff2") format("woff2"), url("/fonts/ptn77f-webfont.woff") format("woff");
}

@font-face {
  font-family: "PT Serif";
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/ptf55f-webfont.woff2") format("woff2"), url("/fonts/ptf55f-webfont.woff") format("woff");
}

@font-face {
  font-family: "PT Serif";
  font-style: italic;
  font-weight: 400;
  src: url("/fonts/ptf56f-webfont.woff2") format("woff2"), url("/fonts/ptf56f-webfont.woff") format("woff");
}

@font-face {
  font-family: "PT Serif";
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/ptf75f-webfont.woff2") format("woff2"), url("/fonts/ptf75f-webfont.woff") format("woff");
}

@font-face {
  font-family: "PT Serif";
  font-style: italic;
  font-weight: 700;
  src: url("/fonts/ptf76f-webfont.woff2") format("woff2"), url("/fonts/ptf76f-webfont.woff") format("woff");
}

/*v "But icon fonts are BAAAD!", I (Ralph Haygood) hear you cry (if you're a conventionally brought-up web programmer); see, for example,                    */
/*v                                                                                                                                                          */
/*v https://www.irigoyen.dev/blog/2021/02/17/stop-using-icon-fonts/                                                                                          */
/*v                                                                                                                                                          */
/*v (if you haven't heard it all already). As with most moralizing about programming, reality isn't so simple. That blog post accuses icon fonts of being    */
/*v "difficult to style/position". However, that depends on what you wish to style or position icons with respect to. If, say, you want an icon to appear    */
/*v immediately before or after a string and, perhaps, share the size or color of the string, there's no easier way than to include the icon in the string.  */
/*v                                                                                                                                                          */
/*v For example, the FormLabel component (see app/client/vues/shared/forms/FormLabel.vue) needs to place a help nubbin immediately after a label string. The */
/*v string and nubbin together must occupy no more than a certain maximum width (namely 25% of the width of the parent form component). Accordingly, if the  */
/*v string is too long, it should be broken into two lines (or, conceivably, more). If the nubbin were a free-standing SVG, getting it to follow the string, */
/*v both when the string is broken and when it isn't, and never on a line by itself, would be a hassle - not impossible, but a hassle. As things are, with   */
/*v the nubbin available in a font, it's trivial.                                                                                                            */
@font-face {
  font-family: "help-nubbin";
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/help_nubbin.woff") format("woff");
  font-display: block;
}

[class^="help-nubbin-"], [class*=" help-nubbin-"] {
  font-style: normal;
  font-weight: 400;
  font-family: "help-nubbin" !important;
  speak: never;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.help-nubbin-help:before {
  content: "\e900";
}
