/* This file is meant to be mixed into formInputs; see ./README. */

@import "./formItem";

.form-input {
  &.disabled {
    .note-container, .modifiers-container {
      @apply opacity-50;

      a, button {
	@apply text-content--text-2;
      }
    }
  }
}
