/* See app/views/layouts/_manifest.html.erb. */

#manifest {
  transition: transform 0.1s;
  @apply absolute;
  /*v Should match height of #header, plus 2px to avoid interference with box shadow on #header; see ./header.scss and ./tailwind.config.js. */
  top: calc(3rem + 2px); /* 48px + 2px */
  @screen tablet {
    top: calc(4rem + 2px); /* 64px + 2px */
  }
  transform: translate(-100%, 0);
  /*v In document stacking context. Below #header-wrapper. */
  @apply z-10;
  @apply box-shadows;
  /*v Should complement height of #header, minus 2px to avoid interference with box shadow on #header; see ./header.scss and ./tailwind.config.js. */
  height: calc(100*var(--vh) - 3rem - 2px);
  @screen tablet {
    height: calc(100*var(--vh) - 4rem - 2px);
  }
  max-width: theme("screens.phone");
  @apply overflow-y-scroll overscroll-none;
  scroll-behavior: smooth;
  @apply bg-chrome--background-2;
  padding: 0.9375rem 0 0.9375rem 0.9375rem; /* 15px 0 15px 15px */
  @apply text-base-phone leading-base-phone;
  @screen tablet {
    padding: 1.25rem 0 1.25rem 1.25rem; /* 20px 0 20px 20px */
    @apply text-base leading-base;
  }
  @apply font-sans;

  &.is-open {
    transform: translate(0, 0);
  }

  li {
    margin-top: 0.5625rem; /* 9px */
    @apply border-r-3;
    @screen tablet {
      margin-top: 0.75rem; /* 12px */
      @apply border-r-4;
    }
    @apply border-chrome--background-2;
    padding-right: 0.75rem; /* 12px */
    @screen tablet {
      padding-right: 1rem; /* 16px */
    }

    &:first-child {
      @apply mt-0;
    }

    &.level-1 {
      padding-left: 0.9375rem; /* 15px */
      @screen tablet {
	padding-left: 1.25rem; /* 20px */
      }
    }

    &.active {
      @apply border-content-control--text-hover;

      a {
	@apply text-content-control--text-hover;
      }
    }
  }
}
