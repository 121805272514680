































.help-tip-opener {
  .string-and-help-nubbin {
    @apply cursor-pointer;

    .help-nubbin-help {
      &.md {
        @apply text-md;
      }

      &:before {
        @apply text-help-nubbin--background;
      }
    }

    @media (hover: hover) {
      &:hover {
	.help-nubbin-help:before {
	  @apply text-help-nubbin--background-hover;
	}
      }
    }
  }

  &.active {
    .help-nubbin-help:before {
      @apply text-help-nubbin--background-active;
    }
  }
}
