/* This file is meant to be mixed into formFields; see ./README. */

/* This file is meant to be mixed into formVisorables; see ./README. */

/* This file is meant to be mixed into formInputs; see ./README. */

/* This file is meant to be mixed into formItems; see ./README. */

.form-item {
  margin-top: 1.125rem;
  /* 18px */
  /*v Ad hoc. */
}

@media (min-width: 576px) {
  .form-item {
    margin-top: 1.5rem;
    /* 24px */
  }
}

.form-item:first-child:not(.noninitial) {
  margin-top: 0px;
}

@media (min-width: 576px) {
  .form-item:first-child:not(.noninitial) {
    margin-top: 0px;
  }
}

.form-item.group-with-item-above {
  margin-top: 0.375rem;
  /* 6px */
}

@media (min-width: 576px) {
  .form-item.group-with-item-above {
    margin-top: 0.5rem;
    /* 8px */
  }
}

.form-item .row-2 .column-2 {
  /*v Relative-positioned so it's the containing block for FormErrorTip (except in FormCheckbox). */
  position: relative;
}

.form-item.labels-at-left .row-1 {
  /*v Relative-positioned so it's the containing block for notes and modifiers (except in FormCheckbox [deleted]). */
  position: relative;
}

.form-item.labels-at-left .row-2 {
  /*v Justification is irrelevant, because the widths of the columns sum to 100%. The only reason for making the row a flexbox is to make the columns */
  /*v vertically centered within it.                                                                                                                  */
  display: flex;
  align-items: center;
}

.form-item.labels-at-left .row-2 .column-1, .form-item.labels-at-left .row-2 .column-3 {
  width: 25%;
}

.form-item.labels-at-left .row-2 .column-2 {
  width: 50%;
}

.form-item.labels-on-top .row-1 {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.form-item .note-container, .form-item .modifiers-container {
  --tw-text-opacity: 1;
  color: rgba(127, 127, 127, var(--tw-text-opacity));
  font-size: 0.625rem;
  line-height: 1;
}

@media (min-width: 576px) {
  .form-item .note-container, .form-item .modifiers-container {
    font-size: 0.75rem;
  }
}

.form-item .note-container {
  font-style: italic;
}

.form-item.labels-at-left .row-1 {
  /*^ Avoid affecting FormCheckbox, whose note is in row 2. */
}

.form-item.labels-at-left .row-1 .note-container, .form-item.labels-at-left .row-1 .modifiers-container {
  position: absolute;
  top: -0.703125rem;
  /* -11.25px */
}

@media (min-width: 576px) {
  .form-item.labels-at-left .row-1 .note-container, .form-item.labels-at-left .row-1 .modifiers-container {
    top: -0.9375rem;
    /* -15px */
  }
}

.form-item.labels-at-left .row-1 .note-container {
  left: 25%;
}

.form-item.labels-at-left .row-1 .modifiers-container {
  right: 25%;
}

.form-item .auxiliary .auxiliary-content {
  /*v The auxiliary may be disabled even when the formItem (if it's a formInput and hence disableable) isn't. */
}

.form-item .auxiliary .auxiliary-content button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.3125rem;
  /* 21px */
  width: 100%;
  border-radius: 9999px;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(199, 44, 64, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(199, 44, 64, var(--tw-text-opacity));
}

@media (hover: hover) {
  .form-item .auxiliary .auxiliary-content button:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 110, 128, var(--tw-border-opacity));
    --tw-text-opacity: 1;
    color: rgba(255, 110, 128, var(--tw-text-opacity));
  }
}

@media (min-width: 576px) {
  .form-item .auxiliary .auxiliary-content button {
    height: 1.75rem;
    /* 28px */
  }
}

.form-item .auxiliary .auxiliary-content button .icon {
  height: 0.875rem;
  /* 14px */
  width: 0.875rem;
  /* 14px */
  margin-right: 0.125rem;
  /* 2px */
  fill: currentColor;
}

@media (min-width: 576px) {
  .form-item .auxiliary .auxiliary-content button .icon {
    height: 1.125rem;
    /* 18px */
    width: 1.125rem;
    /* 18px */
  }
}

.form-item .auxiliary .auxiliary-content.disabled button {
  pointer-events: none;
  --tw-border-opacity: 1;
  border-color: rgba(106, 106, 106, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(241, 241, 241, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(106, 106, 106, var(--tw-text-opacity));
}

.form-item.labels-at-left .auxiliary {
  margin-left: 25%;
  width: 75%;
}

.form-item.labels-on-top .auxiliary {
  margin-top: 0.75rem;
  /* 12px */
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

@media (min-width: 576px) {
  .form-item.labels-on-top .auxiliary {
    margin-top: 1rem;
    /* 16px */
  }
}

.form-input.disabled .note-container, .form-input.disabled .modifiers-container {
  opacity: 0.5;
}

.form-input.disabled .note-container a, .form-input.disabled .note-container button, .form-input.disabled .modifiers-container a, .form-input.disabled .modifiers-container button {
  --tw-text-opacity: 1;
  color: rgba(127, 127, 127, var(--tw-text-opacity));
}

.form-visorable .visor {
  border-radius: 0 0 0.3125rem 0.3125rem;
  /* 0 0 5px 5px */
  --tw-bg-opacity: 1;
  background-color: rgba(241, 241, 241, var(--tw-bg-opacity));
  padding: 0.375rem 0.4375rem;
  /* 6px 7px */
}

@media (min-width: 576px) {
  .form-visorable .visor {
    padding: 0.5rem 0.5625rem;
    /* 8px 9px */
  }
}

.form-visorable.labels-at-left .visor {
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.form-visorable.labels-on-top .visor {
  width: 100%;
}

.form-field input, .form-field textarea {
  display: block;
  width: 100%;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(132, 132, 132, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(26, 26, 26, var(--tw-text-opacity));
}

.form-field input::placeholder, .form-field textarea::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(155, 155, 155, var(--tw-placeholder-opacity));
}

@media (hover: hover) {
  .form-field input:hover, .form-field textarea:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(250, 250, 250, var(--tw-bg-opacity));
  }
}

.form-field input, .form-field textarea {
  font-size: 0.8125rem;
  /*v Generate another rule with the same selector, so that the focus style overrides the hover style. */
}

@media (min-width: 576px) {
  .form-field input, .form-field textarea {
    font-size: 1rem;
  }
}

.form-field input:focus, .form-field textarea:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(241, 241, 241, var(--tw-bg-opacity));
}

.form-field input {
  height: 2.5rem;
  /* 40px */
  border-radius: 0.3125rem;
  /* 5px */
  padding: 0 0.375rem;
  /* 0 6px */
  line-height: 1;
  /*v Per                                    */
  /*v                                        */
  /*v https://stackoverflow.com/a/60987373 . */
}

@media (min-width: 576px) {
  .form-field input {
    padding: 0 0.5rem;
    /* 0 8px */
  }
}

.form-field input:-webkit-autofill::first-line {
  font-size: 0.8125rem;
  font-family: inherit;
}

@media (min-width: 576px) {
  .form-field input:-webkit-autofill::first-line {
    font-size: 1rem;
  }
}

.form-field textarea {
  resize: none;
  border-top-left-radius: 0.3125rem;
  /* 5px */
  border-top-right-radius: calc(var(--scrollbar-width-is-zero)*0.3125rem);
  /* 5px or 0 */
  border-bottom-right-radius: calc(var(--scrollbar-width-is-zero)*0.3125rem);
  /* 5px or 0 */
  border-bottom-left-radius: 0.3125rem;
  /* 5px */
  overflow-y: scroll;
  overscroll-behavior: none;
  padding: 0.1875rem 0.375rem;
  /* 3px 6px */
  line-height: 1.84;
}

@media (min-width: 576px) {
  .form-field textarea {
    padding: 0.25rem 0.5rem;
    /* 4px 8px */
    line-height: 1.67;
  }
}

.form-field.with-visor input, .form-field.with-visor textarea {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.form-field.disabled {
  pointer-events: none;
}

.form-field.disabled input, .form-field.disabled textarea {
  --tw-bg-opacity: 1;
  background-color: rgba(204, 204, 204, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(106, 106, 106, var(--tw-text-opacity));
  opacity: 0.5;
}

.form-text-field input.lower-case {
  text-transform: lowercase;
}
