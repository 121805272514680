











































































































.tip {
  /*v --spur-height is sqrt(3)/2 times --spur-width. */
  --spur-height: 0.405949rem; /* 6.49519px */
  --spur-width: 0.46875rem; /* 7.5px */
  @screen tablet {
    --spur-height: 0.541266rem; /* 8.66025px */
    --spur-width: 0.625rem; /* 10px */
  }

  @apply absolute;
  /* If needed, the z-index should be specified by a rule with this selector in the parent component. */
  /*v help-tip--background is a medium gray, so the standard drop-shadows look a little smudgy. */
  @apply help-tip-drop-shadows;
  @apply cursor-default w-max;
  /*v Second argument should be 67% of max-width of #content; see ../../../stylesheets/content.scss. */
  max-width: calc(min(67vw, 31rem)); /* 496px */
  @screen tablet {
    /*v Second argument should be 50% of max-width of #content; see ../../../stylesheets/content.scss. */
    max-width: calc(min(50vw, 23rem)); /* 368px */
  }
  border-radius: 0.1875rem; /* 3px */
  @apply bg-help-tip--background;

  &::before {
    content: "";
    @apply absolute;
    z-index: -1;
    height: calc(1px + var(--spur-height));
    width: var(--spur-width);
    @apply bg-help-tip--background;
  }

  &.opens-downward {
    top: calc(var(--top) + var(--offset-bottom) + var(--spur-height));

    &::before {
      clip-path: polygon(0 100%, 0 calc(100% - 1px), 50% 0, 100% calc(100% - 1px), 100% 100%);
      bottom: calc(100% - 1px);
    }
  }

  &.opens-upward {
    bottom: calc(var(--bottom) + var(--offset-top) + var(--spur-height));

    &::before {
      clip-path: polygon(0 0, 0 1px, 50% 100%, 100% 1px, 100% 0);
      top: calc(100% - 1px);
    }
  }

  &.opens-rightward {
    &.spur-is-at-center-of-opener {
      left: calc(var(--left) + var(--offset-right) - var(--spur-width)/2);
    }

    &.spur-is-at-edge-of-opener {
      left: calc(var(--left) + var(--offset-right));
    }

    &::before {
      @apply left-0;
    }
  }

  &.opens-leftward {
    &.spur-is-at-center-of-opener {
      right: calc(var(--right) + var(--offset-left) - var(--spur-width)/2);
    }

    &.spur-is-at-edge-of-opener {
      right: calc(var(--right) + var(--offset-left));
    }

    &::before {
      @apply right-0;
    }
  }

  &.opens-downward {
    &.opens-rightward {
      @apply rounded-tl-none;
    }

    &.opens-leftward {
      @apply rounded-tr-none;
    }
  }

  &.opens-upward {
    &.opens-rightward {
      @apply rounded-bl-none;
    }

    &.opens-leftward {
      @apply rounded-br-none;
    }
  }

  .paragraphed-string {
    /*v The 1rem is a bottom or top margin. */
    max-height: calc(var(--max-height) - var(--spur-height) - 1rem); /* 16px */
    @apply overflow-auto overscroll-none;
    padding: 0.328125rem 0.4375rem 0.28125rem; /* 5.25px 7px 4.5px */
    @screen tablet {
      padding: 0.4375rem 0.5625rem 0.375rem; /* 7px 9px 6px */
    }

    /deep/ p {
      margin-top: 0.375rem; /* 6px */
      @screen tablet {
	margin-top: 0.5rem; /* 8px */
      }
      @apply cursor-text text-help-tip--text font-normal;
      @apply text-sm-phone leading-sm-phone;
      @screen tablet {
	@apply text-sm leading-sm;
      }
      @apply font-sans;

      &:first-child {
	@apply mt-0;
      }
    }

    /deep/ a {
      @apply text-current;
      /* TODO: Hover effect? */
    }
  }
}
