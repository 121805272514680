



































.form-label {
  &.labels-on-top {
    /*v In case there's both a note and modifiers, push the note to the right. */
    @apply flex-grow;
  }

  .label {
    @apply font-bold;
    @apply text-base-phone;
    @screen tablet {
      @apply text-base;
    }
    @apply font-sans-narrow;

    /deep/ .tip {
      /*v In ancestral stacking context. Above FormErrorTip. */
      @apply z-20;
    }
  }

  &.labels-at-left {
    .label {
      /*v Ordinarily, labels shouldn't wrap, but in case they do, allow a little leading. */
      @apply leading-tight;
    }
  }

  &.labels-on-top {
    .label {
      height: 1.125rem; /* 18px */
      padding-top: 0.09375rem; /* 1.5px */
      @screen tablet {
	height: 1.5rem; /* 24px */
	padding-top: 0.125rem; /* 2px */
      }
      @apply leading-none;
    }
  }

  &.disabled {
    @apply opacity-50 pointer-events-none;
  }
}
