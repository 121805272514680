/* This file is meant to be mixed into formVisorables; see ./README. */

@import "./formInput";

.form-visorable {
  .visor {
    border-radius: 0 0 0.3125rem 0.3125rem; /* 0 0 5px 5px */
    @apply bg-form-visorable-visor--background;
    padding: 0.375rem 0.4375rem; /* 6px 7px */
    @screen tablet {
      padding: 0.5rem 0.5625rem; /* 8px 9px */
    }
  }

  &.labels-at-left {
    .visor {
      @apply mx-auto w-1/2;
    }
  }

  &.labels-on-top {
    .visor {
      @apply w-full;
    }
  }
}
