.dialog {
  /*v Make root z-indexable. */
  position: relative;
  /*v In document stacking context. Above all (except subsequent dialogs or flickers). */
  z-index: 50;
}

.dialog .scrim.fullscreen {
  display: none;
}

.dialog .scrim:not(.fullscreen) {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.25);
}

.dialog .box {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: var(--box-width);
}

.dialog .box.fullscreen {
  --box-width: 100%;
}

.dialog .box:not(.fullscreen).wide {
  /*^ not(.fullscreen) and .wide implies "@screen tablet". */
  /*v Second argument should be 89% of max-width of #content; see ../../../stylesheets/content.scss. */
  --box-width: calc(min(89vw, 41rem));
  /* 656px */
}

.dialog .box:not(.fullscreen):not(.wide) {
  --box-width: 18rem;
  /* 288px */
}

@media (min-width: 576px) {
  .dialog .box:not(.fullscreen):not(.wide) {
    --box-width: 24rem;
    /* 384px */
  }
}

.dialog .box.fullscreen {
  top: 0px;
  left: 0px;
  height: 100%;
}

.dialog .box:not(.fullscreen) {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 0.25rem rgba(255, 255, 255, 1.0), 0 0.0625rem 0.25rem rgba(0, 0, 0, 0.75);
  border-radius: 0.1875rem;
  /* 3px */
  /*v Works around a defect of Chrome. The defect is in the implementation of clip-path, which is applied to .header and .footer below. Intermittently, a */
  /*v row or column of pixels is clipped from an edge with inset 0, exposing the background of .box.                                                      */
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}

.dialog .box:not(.fullscreen).height-specified {
  height: var(--box-height);
}

.dialog .box:not(.fullscreen):not(.height-specified) {
  /*v Ad hoc. */
  max-height: 90vh;
}

.dialog .box .header {
  /*v Make header z-indexable. */
  position: relative;
  /*v In root stacking context. Above .body-wrapper. */
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0 0.25rem rgba(255, 255, 255, 1.0), 0 0.0625rem 0.25rem rgba(0, 0, 0, 0.75);
  height: 2.625rem;
  /* 42px */
  border-radius: inherit;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  padding: 0 0.9375rem;
  /* 0 15px */
}

@media (min-width: 576px) {
  .dialog .box .header {
    height: 3.5rem;
    /* 56px */
  }
}

@media (min-width: 576px) {
  .dialog .box .header {
    padding: 0 1.25rem;
    /* 0 20px */
  }
}

.dialog .box .header .heading {
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgba(237, 237, 237, var(--tw-text-opacity));
  font-size: 0.9375rem;
  line-height: 1;
}

@media (min-width: 576px) {
  .dialog .box .header .heading {
    font-size: 1.25rem;
  }
}

.dialog .box .header .close-button {
  /*v Ad hoc. */
  margin-right: -0.125rem;
  /* -2px */
}

.dialog .box .header .close-button .icon {
  height: 0.75rem;
  /* 12px */
  width: 0.75rem;
  /* 12px */
  fill: currentColor;
  --tw-text-opacity: 1;
  color: rgba(237, 237, 237, var(--tw-text-opacity));
}

@media (hover: hover) {
  .dialog .box .header .close-button .icon:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
}

@media (min-width: 576px) {
  .dialog .box .header .close-button .icon {
    height: 1rem;
    /* 16px */
    width: 1rem;
    /* 16px */
  }
}

.dialog .box:not(.fullscreen) .header {
  /*v Confines the box-shadows to the bottom edge, else they would deepen the shadows around .box. The -1rem is quasi-arbitrary; the magnitude simply */
  /*v must be great enough to accommodate the shadows.                                                                                                */
  -webkit-clip-path: inset(0 0 -1rem 0 round 0.1875rem 0.1875rem 0 0);
  clip-path: inset(0 0 -1rem 0 round 0.1875rem 0.1875rem 0 0);
  /* 0 0 -16px 0 round 3px 3px 0 0 */
}

.dialog .box .body-wrapper {
  flex-grow: 1;
  overflow: auto;
  scroll-behavior: smooth;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.dialog .box .body-wrapper.no-header {
  border-radius: inherit;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.dialog .box .body-wrapper .body {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: calc(var(--box-width) - var(--scrollbar-width));
  border-radius: inherit;
  overflow: visible;
  padding: 0.9375rem;
  /* 15px */
}

@media (min-width: 576px) {
  .dialog .box .body-wrapper .body {
    padding: 1.25rem;
    /* 20px */
  }
}

.dialog .box .body-wrapper .body:not(.right-margin-should-exclude-scrollbar) {
  padding-right: calc(max(calc(0.9375rem - var(--scrollbar-width)), 0px));
  /* 15px */
}

@media (min-width: 576px) {
  .dialog .box .body-wrapper .body:not(.right-margin-should-exclude-scrollbar) {
    padding-right: calc(max(calc(1.25rem - var(--scrollbar-width)), 0px));
    /* 20px */
  }
}

.dialog .box .body-wrapper .body .icon-container {
  margin-bottom: 0.375rem;
  /* 6px */
  padding-right: 0.75rem;
  /* 12px */
}

@media (min-width: 576px) {
  .dialog .box .body-wrapper .body .icon-container {
    margin-bottom: 0.5rem;
    /* 8px */
    padding-right: 1rem;
    /* 16px */
  }
}

.dialog .box .body-wrapper .body .icon-container .icon {
  flex-shrink: 0;
  height: 2.25rem;
  /* 36px */
  width: 2.25rem;
  /* 36px */
}

@media (min-width: 576px) {
  .dialog .box .body-wrapper .body .icon-container .icon {
    height: 3rem;
    /* 48px */
    width: 3rem;
    /* 48px */
  }
}

.dialog .box .body-wrapper .body .content-string-container /deep/ p {
  margin-bottom: 0.375rem;
  /* 6px */
  font-size: 0.71875rem;
  line-height: 1.94;
}

@media (min-width: 576px) {
  .dialog .box .body-wrapper .body .content-string-container /deep/ p {
    margin-top: 0.5rem;
    /* 8px */
    font-size: 0.875rem;
    line-height: 1.78;
  }
}

.dialog .box .body-wrapper .body .content-string-container /deep/ p:first-child {
  margin-top: 0px;
}

.dialog .box .body-wrapper .body .content-string-container /deep/ p.note {
  font-size: 0.625rem;
  line-height: 2.05;
}

@media (min-width: 576px) {
  .dialog .box .body-wrapper .body .content-string-container /deep/ p.note {
    font-size: 0.75rem;
    line-height: 1.9;
  }
}

.dialog .box .body-wrapper .body .content-component-instance-container {
  width: 100%;
}

.dialog .box .footer {
  /*v Make footer z-indexable. */
  position: relative;
  /*v In root stacking context. Above .body-wrapper. (This isn't strictly needed, as .body-wrapper is earlier in the document flow, but it doesn't hurt.) */
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-shadow: 0 0 0.25rem rgba(255, 255, 255, 1.0), 0 0.0625rem 0.25rem rgba(0, 0, 0, 0.75);
  height: 2.625rem;
  /* 42px */
  border-radius: inherit;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}

@media (min-width: 576px) {
  .dialog .box .footer {
    height: 3.5rem;
    /* 56px */
  }
}

.dialog .box .footer button {
  margin-right: 0.65625rem;
  /* 10.5px */
  height: 1.3125rem;
  /* 21px */
  width: 3.75rem;
  /* 60px */
  border-radius: 9999px;
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgba(237, 237, 237, var(--tw-text-opacity));
}

@media (hover: hover) {
  .dialog .box .footer button:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
}

.dialog .box .footer button {
  font-size: 0.8125rem;
  /*v "@apply leading-none" places the label 1px too far down, at least in Chrome, for reasons unfathomable. */
  line-height: 1.3125rem;
  /* 21px */
  font-family: PT Sans Narrow, sans-serif;
}

@media (min-width: 576px) {
  .dialog .box .footer button {
    margin-right: 0.875rem;
    /* 14px */
    height: 1.75rem;
    /* 28px */
    width: 5rem;
    /* 80px */
  }
}

@media (min-width: 576px) {
  .dialog .box .footer button {
    font-size: 1rem;
    line-height: 1;
  }
}

.dialog .box .footer button.go-button {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 173, 0, var(--tw-bg-opacity));
}

@media (hover: hover) {
  .dialog .box .footer button.go-button:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 194, 0, var(--tw-bg-opacity));
  }
}

.dialog .box .footer button.go-button.disabled {
  pointer-events: none;
  --tw-bg-opacity: 1;
  background-color: rgba(103, 103, 103, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(203, 203, 203, var(--tw-text-opacity));
  opacity: 0.5;
}

.dialog .box .footer button.stop-button {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 38, 38, var(--tw-bg-opacity));
}

@media (hover: hover) {
  .dialog .box .footer button.stop-button:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 74, 74, var(--tw-bg-opacity));
  }
}

.dialog .box:not(.fullscreen) .footer {
  /*v Confines the box-shadows to the top edge, else they would deepen the shadows around .box. The -1rem is quasi-arbitrary; the magnitude simply must */
  /*v be great enough to accommodate the shadows.                                                                                                       */
  -webkit-clip-path: inset(-1rem 0 0 0 round 0 0 0.1875rem 0.1875rem);
  clip-path: inset(-1rem 0 0 0 round 0 0 0.1875rem 0.1875rem);
  /* -16px 0 0 0 round 0 0 3px 3px */
}
