/* fade-fast */

.fade-fast-appear, .fade-fast-enter, .fade-fast-leave-to {
  @apply opacity-0;
}

.fade-fast-appear-active, .fade-fast-enter-active, .fade-fast-leave-active {
  transition: all 0.1s;
}

/* fade-fast-unless-phone */

.fade-fast-unless-phone-appear, .fade-fast-unless-phone-enter, .fade-fast-unless-phone-leave-to {
  @apply opacity-0;
}

.fade-fast-unless-phone-appear-active, .fade-fast-unless-phone-enter-active, .fade-fast-unless-phone-leave-active {
  transition: all 0;
  @screen tablet {
    transition: all 0.1s;
  }
}
