/* Tailwind */

@tailwind base;
@tailwind components;
@tailwind utilities;


/* "Free" isn't free */

@import "body";
@import "content";
@import "fonts";
@import "header";
@import "home";
@import "html";
@import "manifest";
@import "markdown";
@import "transitions";
