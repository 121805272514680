/* See app/views/layouts/application.html.erb. */

#content-wrapper {
  /*v Make this element what might be called the scroll parent of last resort for absolute-positioned descendants such as help tips. */
  @apply relative;
  /*v Should complement height of #header, minus 2px to avoid interference with box shadow on #header; see ./header.scss and ./tailwind.config.js. */
  height: calc(100*var(--vh) - 3rem - 2px);
  @screen tablet {
    height: calc(100*var(--vh) - 4rem - 2px);
  }
  @apply overflow-y-scroll overscroll-none;
  scroll-behavior: smooth;

  #content {
    /*v Duration should match fade-fast; see ./transitions.scss. */
    transition: opacity 0.1s;
    @apply opacity-0 mx-auto;
    /*v Should match #header; see ./header.scss. Influences calculation of width of .dialog .box.not-fullscreen.wide and max-width of .tip; see */
    /*v ../vues/shared/dialogs/DialogComponent.vue and ../vues/shared/tips/TipComponent.vue.                                                    */
    max-width: 46rem; /* 736px */
    /*v Horizontal padding should match #header; see ./header.scss. */
    padding: 1.125rem 1.3125rem; /* 18px 21px */
    @screen tablet {
      /*v Right padding should match right margin and width of .image; see ./home.scss. */
      padding: 1.5rem 1.75rem; /* 24px 28px */
    }

    &.is-visible {
      @apply opacity-100;
    }
  }
}
