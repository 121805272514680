/* See app/views/layouts/_header.html.erb. */

#header-wrapper {
  /*v Make z-indexable. */
  @apply relative;
  /*v In document stacking context. Above #manifest. */
  @apply z-20;
  /*v Avoid interference with box shadow; see ./tailwind.config.js. */
  margin-bottom: 2px;
  @apply box-shadows bg-chrome--background-1;
  padding-right: var(--scrollbar-width);

  #header {
    @apply flex justify-between items-center mx-auto;
    /*v Should complement height of #content-wrapper and #manifest; see ./content.scss and ./manifest.scss. */
    height: 3rem; /* 48px */
    @screen tablet {
      height: 4rem; /* 64px */
    }
    /*v Should match #content; see ./content.scss. */
    max-width: 46rem; /* 736px */
    /*v Horizontal padding should match #content; see ./content.scss. */
    padding: 0 1.3125rem; /* 0 21px */
    @screen tablet {
      padding: 0 1.75rem; /* 0 28px */
    }
    @apply font-bold;
    @apply text-base-phone;
    @screen tablet {
      @apply text-base;
    }
    @apply leading-none font-sans;

    .logo {
      @apply text-2xl-phone;
      @screen tablet {
	@apply text-2xl;
      }
      @apply leading-none;

      a {
	@apply text-chrome-logo--text if-hover-is-supported:hover:text-chrome-logo--text-hover;
      }
    }

    a, button, input[type="submit"] {
      @apply text-chrome--text if-hover-is-supported:hover:text-chrome-control--text-hover fill-current;

      &.disabled {
	@apply pointer-events-none text-chrome-control--text-disabled;
      }
    }

    #log-out-button {
      /*v The button_to helper attaches the log-out-button class to an input[type="submit"] element. The leading-none class applied to the #header element */
      /*v causes the descender of the "g" in "Log" to be cut off, for reasons unclear.                                                                     */
      @apply leading-base-phone;
      @screen tablet {
	@apply leading-base;
      }
    }

    .with-icon {
      @apply flex justify-start items-center;

      .icon {
	height: theme("fontSize.xl-phone");
	width: theme("fontSize.xl-phone");
	@screen tablet {
	  height: theme("fontSize.xl");
	  width: theme("fontSize.xl");
	}
      }

      .label {
	@apply hidden;
	@screen tablet {
	  @apply inline;
	}
      }
    }
  }
}
